<template>
    <div class="tab-feed" v-loading="loading">
        <el-table :data="feedData" style="width: 100%">
            <el-table-column
                prop="createTime"
                label="反馈时间"
                width="200"
                show-overflow-tooltip="true"
            />
            <el-table-column
                prop="articleTitle"
                label="对应问题"
                width="140"
                show-overflow-tooltip="true"
            />
            <el-table-column prop="questions" label="反馈内容" show-overflow-tooltip="true" />
        </el-table>
    </div>
    <div class="page" v-if="feedData">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handleSize"
        ></el-pagination>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, toRefs } from 'vue'
import { getFeed } from '../../service/request'
import { ElMessage } from "element-plus";
export default defineComponent({
    name: 'feedBack',
    setup() {
        const feedData = ref('')
        const loading = ref(false)
        const state = reactive({
            pageSize: '',
            pageNumber: '',
            total: ''
        })
        const getFeedData = (params) => {
            loading.value = true
            getFeed(params).then(res => {
                feedData.value = res.data.data.data
                state.pageSize = res.data.data.pageSize
                state.pageNumber = res.data.data.pageNumber
                state.total = res.data.data.totalCount
                loading.value = false
            }).catch(err => {
                loading.value = false
                ElMessage({
                    message: "游览失败",
                    type: "error",
                })
            })
        }


        const handleSize = (size) => {
            state.pageNumber = size
            let params = {
                page: state.pageNumber,
                size: state.pageSize
            }
            getFeedData(params)
        }
        onMounted(() => {
            getFeedData()
        })
        return {
            feedData,
            handleSize,
            loading,
            getFeedData,
            ...toRefs(state)

        }
    },
})
</script>

<style lang="less" scoped>
.el-pagination {
    padding: 20px;
    float: right;
}
</style>
